import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FAQData from "./Data/Faq"; // Import FAQData from its own file
import DOMPurify from "dompurify";

const Faq = ({ faqList }) => {
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return DOMPurify.sanitize(txt.value, {
      ALLOWED_TAGS: [
        "b",
        "i",
        "em",
        "span",
        "br",
        "strong",
        "a",
        "p",
        "ul",
        "ol",
        "li",
        "blockquote",
        "table",
        "tr",
        "td",
        "th",
        "thead",
        "tbody",
        "tfoot",
      ],
      ALLOWED_ATTR: ["href", "target", "style"],
    });
  };

  // State to track visibility of answers and active question
  const [visibleAnswers, setVisibleAnswers] = useState(
    Array(FAQData.length).fill(false)
  );
  const [activeQuestion, setActiveQuestion] = useState(null);

  // Function to toggle visibility of answer and set active question
  const toggleAnswerVisibility = (index) => {
    const newVisibleAnswers = [...visibleAnswers];
    newVisibleAnswers[index] = !newVisibleAnswers[index];
    setVisibleAnswers(newVisibleAnswers);
    setActiveQuestion(index === activeQuestion ? null : index);
  };

  return (
    <Container>
      {" "}
      {/* Set background color for the whole container */}
      <Row>
        <Col className="col-md-12 col-12 text-center mt-5 mb-5 ">
          <h1 className="fw-bold">Frequently Asked Questions</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        {faqList.map((faq, index) => (
          <Col key={index} className="col-12 col-md-12 pt-2 pb-3">
            <div
              className={`questions px-4 d-flex pb-2 justify-content-between align-items-center border-bottom ${
                index === activeQuestion ? "text-primary" : ""
              }`}
              onClick={() => toggleAnswerVisibility(index)}
              style={{
                backgroundColor: index === activeQuestion ? "white" : "",
              }}
              // Set background color conditionally
            >
              <h5>{faq.question}</h5>
              <i
                className={`ri-arrow-${
                  visibleAnswers[index] ? "up" : "down"
                }-s-line text-dark fs-4`}
              ></i>
            </div>
            {/* Render answer only if visibleAnswers[index] is true */}
            {visibleAnswers[index] && (
              <div className="answer mx-4 my-2 p-2">
                <div
                  style={{ fontSize: "18px" }}
                  dangerouslySetInnerHTML={{ __html: decodeHtml(faq.answer) }}
                />
              </div>
            )}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Faq;
