export const SITETITLE = "Cosmos IQ";
// export const WEBSITELINK = "http://localhost:3000";
export const WEBSITELINK = "https://www.cosmosiq.ai";
// export const WEBSITELINK = "https://m-staging.matriye.io";
// export const STUDENTLINK = "http://localhost:3002";
export const STUDENTLINK = "https://user.cosmosiq.ai";
// export const STUDENTLINK = "https://m-user.matriye.io";
// export const FACULTYLINK = "http://localhost:3003";
export const FACULTYLINK = "https://faculty.cosmosiq.ai";
// export const FACULTYLINK = "https://m-legaldc.matriye.io";
// export const ERPLINK = "http://localhost:3004";
export const ERPLINK = "https://erp.cosmosiq.ai";
// export const ERPLINK = "https://m-lmserp.matriye.io";

// Razorpay details test
// export const RAZORPAYKEY = "rzp_test_dU45ELth1kn70R";
// export const RAZORPAYKEYSECRET = "ttWmnnyKEdFC5yj4z84Rz26D";
// Razorpay details live
export const RAZORPAYKEY = "rzp_live_4ASDf6xtW7iapr";
export const RAZORPAYKEYSECRET = "ZMMf1gMZhyHJGRe2lijoK8CA";
