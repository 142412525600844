import React from "react";
import { Link } from "react-router-dom";
import { STUDENTLINK } from "../../constants";
import DOMPurify from "dompurify";

const infoBlockData = {
  infoImgUrl:
    "https://img.freepik.com/free-photo/computer-security-with-login-password-padlock_107791-16191.jpg?w=900&t=st=1704698828~exp=1704699428~hmac=e9c5c41a145e064f664dec1dc55a24b7518201cde346d8fdddbfebfe5a3bfa3b",
  infoSubtitle: "Cosmos IQ EdTech",
  infoTitle: "Student Dashboard",
  infoDescription:
    "Access your personalized student dashboard with just a few clicks. Stay organized, track your progress, and engage with your learning journey seamlessly—all in one convenient platform. ",
  buttonsOne: "Contact Sales",
  buttonsLinkOne: "buttonsLinkOne",
};

const Student = () => {
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return DOMPurify.sanitize(txt.value, {
      ALLOWED_TAGS: [
        "b",
        "i",
        "em",
        "span",
        "br",
        "strong",
        "a",
        "p",
        "ul",
        "ol",
        "li",
        "blockquote",
        "table",
        "tr",
        "td",
        "th",
        "thead",
        "tbody",
        "tfoot",
      ],
      ALLOWED_ATTR: ["href", "target", "style"],
    });
  };

  return (
    <>
      <section className="about-info gray-bg" id="about-info">
        <div className="container">
          <div className="wrapper-info-about">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img
                  src={infoBlockData.infoImgUrl}
                  alt={infoBlockData.infoTitle}
                />
              </div>
              <div className="col-md-7">
                <div className="left-space">
                  <h6>{infoBlockData.infoSubtitle}</h6>
                  <h2>{infoBlockData.infoTitle}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(infoBlockData.infoDescription),
                    }}
                  ></div>
                  <br />

                  <Link
                    to={STUDENTLINK}
                    className="btn btn-primary btn-hover-heading-color"
                  >
                    Access Student Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Student;
