import React from "react";
import DOMPurify from "dompurify";

const AboutUsInfoBlock = (props) => {
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return DOMPurify.sanitize(txt.value, {
      ALLOWED_TAGS: [
        "b",
        "i",
        "em",
        "span",
        "br",
        "strong",
        "a",
        "p",
        "ul",
        "ol",
        "li",
        "blockquote",
        "table",
        "tr",
        "td",
        "th",
        "thead",
        "tbody",
        "tfoot",
      ],
      ALLOWED_ATTR: ["href", "target", "style"],
    });
  };

  return (
    <>
      <section className="about-info" id="about-infos">
        <div className="container" id={props.customId}>
          <div className="wrapper-info-about">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img src={props.data.aboutImage} alt="Welcome To Cosmos IQ" />
              </div>
              <div className="col-md-7">
                <h3>{props.data.aboutHeading}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(props.data.aboutParagraphOne),
                  }}
                ></p>
              </div>
              <div className="col-md-12 mt-4">
                <p
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(props.data.aboutParagraphTwo),
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsInfoBlock;
