import { Carousel, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Carousel.css";
import DOMPurify from "dompurify";

const MyCarousel = ({ items }) => {
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return DOMPurify.sanitize(txt.value, {
      ALLOWED_TAGS: [
        "b",
        "i",
        "em",
        "span",
        "br",
        "strong",
        "a",
        "p",
        "ul",
        "ol",
        "li",
        "blockquote",
        "table",
        "tr",
        "td",
        "th",
        "thead",
        "tbody",
        "tfoot",
      ],
      ALLOWED_ATTR: ["href", "target", "style"],
    });
  };

  return (
    <>
      {items ? (
        <Carousel data-bs-theme="dark" controls={false} className="carousel">
          {items.map((page, key) => (
            <Carousel.Item key={key} interval={2000}>
              <Container fluid className="carousel-container d-flex">
                <Row className="">
                  <Col
                    className={`col-12 col-md-7 px-5 d-flex flex-column justify-content-center`}
                  >
                    <h6
                      className="pre-title fw-normal pb-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {page.subHeading}
                    </h6>
                    <h1 className="title fs-4 carousel-heading fw-semibold ">
                      {page.heading}
                    </h1>
                    <p
                      className="mt-3"
                      style={{
                        fontSize: "1px !important",
                        fontWeight: "400",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(page.description),
                      }}
                    ></p>

                    <div className="buttons mt-4 d-flex gap-3">
                      <Link
                        to={`${
                          window.location.pathname.includes(
                            "/cosmos-iq-business"
                          )
                            ? "/cosmos-iq-business/request-demo?featureName=" +
                              page.featureNameSlugUrl
                            : page.buttonsLinkOne
                        }`}
                        className="btn btn-primary btn-hover-heading-color"
                        style={{
                          lineHeight: "0px",
                          height: "0",
                          padding: "17px 12px",
                        }}
                      >
                        {page.buttonsOne}
                      </Link>
                      <Link
                        to={page.buttonsLinkTwo}
                        className="btn btn-primary btn-hover-heading-color"
                        style={{
                          lineHeight: "0px",
                          height: "0",
                          padding: "17px 12px",
                        }}
                      >
                        {page.buttonsTwo}
                      </Link>
                    </div>
                  </Col>
                  <Col className="image-carousel col-md-5 col-12 d-flex align-items-center justify-content-center ">
                    <div className="carousel-image px-2">
                      <img
                        className="carousel-img"
                        style={{ height: "100%", width: "100%" }}
                        src={page.featuredImagePath}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        ""
      )}
    </>
  );
};

export default MyCarousel;
