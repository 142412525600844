import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import Pagination from "../../../pages/pagination";
import NoRecordFound from "../../core/no-record-found/NoRecordFound";
import Loading from "../../global/Loading";
import DOMPurify from "dompurify";

const getOrganization = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "static/organization",
      {
        signal, // Pass the signal to axios
      }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getBlogCategoryData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "blog/category-list", {
      signal, // Pass the signal to axios
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getTagData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "blog/tags-list", {
      signal, // Pass the signal to axios
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const BlogListBody = ({ onLoadingComplete }) => {
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return DOMPurify.sanitize(txt.value, {
      ALLOWED_TAGS: [
        "b",
        "i",
        "em",
        "span",
        "br",
        "strong",
        "a",
        "p",
        "ul",
        "ol",
        "li",
        "blockquote",
        "table",
        "tr",
        "td",
        "th",
        "thead",
        "tbody",
        "tfoot",
      ],
      ALLOWED_ATTR: ["href", "target", "style"],
    });
  };

  const { data: organizationData } = useQuery(
    "organizationData",
    getOrganization,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  const {
    data: categoryListData,
    // error,
    isLoading: categoryListDataLoading,
  } = useQuery("categoryData", getBlogCategoryData, {
    staleTime: 60000, // Cache data for 60 seconds
    cacheTime: 300000, // Cache data for 5 minutes
    retry: 1, // Retry the request if it fails
    refetchOnWindowFocus: false, // Disable refetching on window focus
    onError: (err) => {
      if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
      } else {
        console.error(err);
      }
    },
  });
  const { data: tagListData, isLoading: tagListDataLoading } = useQuery(
    "tagData",
    getTagData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );
  const [blogListData, setBlogList] = useState([]);
  const [blogListRecords, setBlogListRecords] = useState([]);
  const [searchFilterValue, setSearchFilterValue] = useState();

  useEffect(() => {
    getBlogListData();
  }, []);

  const searchParams = new URLSearchParams(window.location.search); //getdata for edit blog
  const querySearch = searchParams.get("q") ? searchParams.get("q") : "";

  const formData = new FormData();
  formData.append("limit", 10);
  formData.append("offset", 0);
  formData.append("searchTerm", querySearch);

  useEffect(() => {
    setSearchFilterValue(
      window.location.pathname.includes(`/blogs`) ? querySearch : ``
    );
  }, [querySearch]);

  const handleChange = (event) => {
    setSearchFilterValue(event.target.value);
  };

  const getBlogListData = async () => {
    await axios
      .post(window.apiBaseUrl + "blog/blog-list", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status === 200) {
          setBlogList(res.data.data);
          setBlogListRecords(res.data.data.totalRecords);
          onLoadingComplete(); // Call callback function to signal loading completion
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const blogRecords = blogListData.blogList
    ? blogListData.blogList.slice(startIdx, endIdx)
    : [];

  const totalPages = Math.ceil(blogListRecords / itemsPerPage);

  if (categoryListDataLoading || tagListDataLoading) return <Loading />;

  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <div className="section section-padding">
        <div className="container">
          <div className="blog-wrapper-02">
            <div className="row justify-content-between">
              {blogRecords.length > 0 ? (
                <div className="col-lg-8">
                  {blogRecords.map((blog, index) => (
                    <div key={index} className="single-blog blog-list">
                      <div className="blog-image">
                        <Link
                          to={`/blogs/${blog.blogCategoryNameSlugUrl}/${blog.blogTitleSlugURL}`}
                        >
                          <img
                            loading="lazy"
                            src={blog.blogImage}
                            alt={blog.authorName}
                          />
                        </Link>
                        <span className="tags">{blog.blogCategoryName}</span>
                      </div>
                      <div className="blog-content">
                        <div className="meta">
                          <a href="/">
                            <i className="fa fa-user-o"></i>
                            {blog.authorName}
                          </a>
                          <a href="/">
                            <i className="fa fa-calendar-o"></i>
                            {blog.dateOfBlog}
                          </a>
                        </div>
                        <h2 className="title">
                          <Link
                            to={`/blogs/${blog.blogCategoryNameSlugUrl}/${blog.blogTitleSlugURL}`}
                          >
                            {blog.blogTitle}
                          </Link>
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: decodeHtml(blog.blogDescriptionOne),
                          }}
                        ></div>
                        <Link
                          to={`/blogs/${blog.blogCategoryNameSlugUrl}/${blog.blogTitleSlugURL}`}
                          className="btn btn-primary btn-hover-heading-color"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  ))}
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                  />
                </div>
              ) : (
                <div className="col-lg-8">
                  <NoRecordFound link="/blogs" buttonName="Explore our Blogs" />
                </div>
              )}

              <div className="col-xl-3 col-lg-4">
                <div className="sidebar-wrap">
                  <div className="sidebar-widget widget-search-desktop">
                    <div className="widget-search">
                      <form action={"/blogs"} autoComplete="off" method="get">
                        <input
                          className="form-control"
                          type="text"
                          name="q"
                          placeholder="Search for blog name, tags"
                          value={searchFilterValue}
                          onChange={handleChange}
                        />
                        <button type="submit">
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="sidebar-widget sidebar-widget-about">
                    <h3>About us</h3>
                    <div className="textwidget">
                      <p>
                        Cosmos IQ EdTech offers career-relevant online
                        E-learning programs the Academic, Professional Courses,
                        and Competitive exams.
                      </p>
                      <h5 className="name">Social Media</h5>
                      <ul className="social-box">
                        <li className="twitter">
                          <a
                            href={`${
                              organizationData
                                ? organizationData.twitterURL
                                : `https://x.com/MatriyeA`
                            }`}
                            target="_blank"
                          >
                            <i className="fa fa-twitter"> </i>
                          </a>
                        </li>
                        <li className="linkedin">
                          <a
                            href={`${
                              organizationData
                                ? organizationData.linkedinURL
                                : `https://www.linkedin.com/company/cosmos-iq-ai/`
                            }`}
                            target="_blank"
                          >
                            <i className="fa fa-linkedin"> </i>
                          </a>
                        </li>
                        <li className="facebook">
                          <a
                            href={`${
                              organizationData
                                ? organizationData.facebookURL
                                : `https://www.facebook.com/CosmosIQ/`
                            }`}
                            target="_blank"
                          >
                            <i className="fa fa-facebook-f"> </i>
                          </a>
                        </li>
                        <li className="instagram">
                          <a
                            href={`${
                              organizationData
                                ? organizationData.instagramURL
                                : `https://www.facebook.com/CosmosIQ/`
                            }`}
                            target="_blank"
                          >
                            <i className="fa fa-instagram"> </i>
                          </a>
                        </li>
                        <li className="pinterest">
                          <a
                            href={`${
                              organizationData
                                ? organizationData.youtubeURL
                                : `https://www.youtube.com/channel/UC0xDyUBPcSoQMxWQWcdwjAg`
                            }`}
                            target="_blank"
                          >
                            <i className="fa fa-youtube"> </i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {blogListData.blogList ? (
                    <div className="sidebar-widget">
                      <h3 className="widget-title">Popular Posts</h3>
                      <div className="widget-post">
                        {blogListData.blogList
                          .slice(0, 3)
                          .map((blogs, index) => (
                            <div key={index} className="single-mini-post">
                              <div className="mini-post-image">
                                <Link
                                  to={`/blogs/${blogs.blogCategoryNameSlugUrl}/${blogs.blogTitleSlugURL}`}
                                >
                                  <img
                                    loading="lazy"
                                    src={blogs.blogImage}
                                    alt={blogs.blogId}
                                  />
                                </Link>
                              </div>
                              <div className="mini-post-content">
                                <h5 className="title">
                                  <Link
                                    to={`/blogs/${blogs.blogCategoryNameSlugUrl}/${blogs.blogTitleSlugURL}`}
                                  >
                                    {blogs.blogTitle}
                                  </Link>
                                </h5>
                                <span className="date">
                                  <i className="fa fa-calendar"></i>
                                  &nbsp;{blogs.dateOfBlog}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {categoryListData ? (
                    <div className="sidebar-widget">
                      <h3 className="widget-title">Categories</h3>
                      <div className="widget-sidebar-link">
                        <ul>
                          {categoryListData.map((category, index) => (
                            <li key={index}>
                              <a href="/">
                                {category.blogCategoryName}{" "}
                                <span>{category.blogCount}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {tagListData ? (
                    <div className="sidebar-widget">
                      <h3 className="widget-title">Tags</h3>
                      <div className="widget-tags">
                        <ul>
                          {tagListData.map((tags, index) => (
                            <li key={index}>
                              <a href="/">{tags.tagsName}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogListBody;
