import React from "react";
import DOMPurify from "dompurify";

const KeyFeatures = ({
  pageTitle,
  pageSubTitle,
  content = [],
  customClass,
  customId,
}) => {
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return DOMPurify.sanitize(txt.value, {
      ALLOWED_TAGS: [
        "b",
        "i",
        "em",
        "span",
        "br",
        "strong",
        "a",
        "p",
        "ul",
        "ol",
        "li",
        "blockquote",
        "table",
        "tr",
        "td",
        "th",
        "thead",
        "tbody",
        "tfoot",
      ],
      ALLOWED_ATTR: ["href", "target", "style"],
    });
  };
  return (
    <>
      <section
        className="about-us-trial career-text feature-intro"
        id={customId}
      >
        <div className="container">
          <h2 className="title fs-2 head-choose">
            <small>{pageSubTitle}</small>
            <br />
            {pageTitle}
          </h2>
          <div className="col-md-12">
            {Array.isArray(content) ? (
              <>
                <div className="row">
                  {content.map((feature, index) => (
                    <div key={index} className={`${customClass} d-flex `}>
                      <div className="feature-inner flex-fill">
                        <div className="icon-box">
                          <img src={feature.whyChooseUsIcon} alt="bg1" />
                        </div>
                        <div className="text-box-new">
                          <h3>{feature.whyChooseUsTitle}</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: decodeHtml(
                                feature.whyChooseUsDescription
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default KeyFeatures;
